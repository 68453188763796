import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import AuthContext from "../context/AuthContext"

const PROFILE_STATUS = {
    "active": "Active",
    "on-hold": "On-Hold",
    "suspended": "Suspended",
    "deactivated": "De-activated"
}


const ProfilePage = () => {

    let {user, authTokens, logoutUser, selectedUser, setSelectedUser} = useContext(AuthContext)

    const navigate = useNavigate()

    const baseURL = process.env.REACT_APP_BASEURL;

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [telephone, setTelephone] = useState('')
    const [status, setStatus] =useState('active')
    const [imageUrl, setImageUrl] = useState('');
    const [error, setError] = useState(null)

    // const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        if (selectedUser) {
            setEmail(selectedUser.email)
            setFirstName(selectedUser.profile.firstName)
            setLastName(selectedUser.profile.lastName)
            setTelephone(selectedUser.profile.telephone)
            setStatus(selectedUser.profile.status)
            setImageUrl(selectedUser.profile.memberPicture)
        } else {
            console.log("Not ready")
        }
    }, [])


    const handleChange = (event) => {
        setStatus(event.target.value);
    };


    let handleBackButton = () => {
        navigate('/')
    }


    const handleSubmit = async () => {

        let profile = {
            id: selectedUser.id,
            email: email,
            profile: {
                firstName: firstName,
                lastName: lastName,
                telephone: telephone,
                status: status
            }
        }
    
        
        // let response = await fetch("http://170.64.162.112:8000/api/update_user_profile/", {
        // let response = await fetch("http://localhost:8000/api/update_user_profile/", {
        let response = await fetch(`${baseURL}/api/update_user_profile/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(profile) // Make sure you are sending 'profile' here
        });

        let data = await response.json()

        if (response.status === 200) {
            navigate('/')
        } else {
            console.log('Error:', response.statusText)
            alert(data);
        }
    
    };


    // const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    // const [previewUrl, setPreviewUrl] = useState(imageUrl);
    const fileInputRef = React.createRef();

    const handleImageClick = () => {
        // Programmatically click the hidden file input
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        // setSelectedFile(event.target.files[0]);

        handleFileUpload(event.target.files[0])

    };

    const handleFileUpload = async (file) => {

        const formData = new FormData();
        formData.append('image', file)
        formData.append('user_id', selectedUser.id)

        try {
            // const response = await fetch('http://170.64.162.112:8000/api/upload_profile_picture/', {
            // const response = await fetch('http://localhost:8000/api/upload_profile_picture/', {
            const response = await fetch(`${baseURL}/api/upload_profile_picture/`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + String(authTokens.access),
                },
            });

            const data = await response.json();

            console.log(data)

            if (response.status === 200) {
                // setUploadStatus(`File uploaded successfully: ${data.file_url}`);
                setImageUrl(data.profile.memberPicture)
            } else {
                setUploadStatus(`Error: ${data.error}`);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('Error uploading file');
        }
    };


    return (
        <div style={{width: "600px"}}>

            <div className="flex-parent-element" style={{paddingLeft: "30px", paddingRight: "30px"}}>

                <div className="flex-child-element">
                    <h2>User Profile</h2>
                </div>



                <div className="flex-child-element" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                    <img src={imageUrl} alt="Loaded from Django" style={{ maxHeight: "150px", maxWidth: "150px", width: "150px", height: "150px", marginBottom: "-30px" }} />
                    {/* <img src={`http:170.64.162.112:8000${imageUrl}`} alt="Loaded from Django" style={{ maxHeight: "150px", maxWidth: "150px", width: "150px", height: "150px", marginBottom: "-30px" }} /> */}
                    {/* <img src={`http://localhost:3000${imageUrl}`} alt="Loaded from Django" style={{ maxHeight: "150px", maxWidth: "150px", width: "150px", height: "150px", marginBottom: "-30px" }} /> */}
                </div>
                
                <div>
                    <input 
                        type="file" 
                        ref={fileInputRef} 
                        onChange={handleFileChange} 
                        style={{ display: 'none' }} 
                    />
                    {/* <button onClick={handleFileUpload}>Upload</button> */}
                    <p>{uploadStatus}</p>
                </div>


                <div className="flex-child-element">
                    <div className="form-group" style={{float: "right"}}>
                        <button onClick={() => {handleBackButton()}}>Back</button>
                    </div>
                </div>

                

            </div>

            <div className='container' style={{width: "100%"}}>


                <div onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            id="email"
                            name="email"
                            style={{paddingInline: "0px", width: "580px", paddingBlock: "0px", padding: "10px"}}
                        />
                    </div>
                    <div className="form-group">
                        <label>First Name:</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            id="firstname"
                            name="firstname"
                            style={{paddingInline: "0px", width: "580px", paddingBlock: "0px", padding: "10px"}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Last Name:</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            id="surname"
                            name="surname"
                            style={{paddingInline: "0px", width: "580px", paddingBlock: "0px", padding: "10px"}}
                        />
                    </div>
                    <div className="form-group">
                        <label>Telephone:</label>
                        <input
                            type="tel"
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                            required
                            id="tel"
                            name="tel"
                            style={{paddingInline: "0px", width: "580px", paddingBlock: "0px", padding: "10px"}}
                        />
                    </div>

                    <div className="form-group">
                        <label>Status:</label>
                        <div>
                            <select value={status} onChange={handleChange} style={
                                    {
                                        backgroundColor: "#3a3a3a",
                                        width: "600px",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        backgroundColor: "#3a3a3a",
                                        borderRadius: "4px"
                                    }
                                }>
                                <option value="" disabled>Select an option</option>
                                {Object.entries(PROFILE_STATUS).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    
                    <div className="form-group">
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </div>

                    <div className="form-group">
                        <button onClick={() => {handleSubmit()}}>Update</button>
                    </div>

                </div>


            </div>


        </div>
    )
}

export default ProfilePage