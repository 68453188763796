import React, { useContext, useEffect, useState } from 'react'
import { AuthProvider } from './context/AuthContext'
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import ProfilePage from "./pages/ProfilePage"

function App() {

  const [user, setUser] = useState(null);

  return (
    <div className="App">
        <Router>
          <AuthProvider>
            <Routes>
                <Route element={<LoginPage />} path='/login' />
                <Route element={<RegisterPage />} path='/register' />
                <Route element={<HomePage />} path='/' exact />
                <Route element={<ProfilePage />} path='/profile' />
            </Routes>
          </AuthProvider>
        </Router>
    </div>
  );
}


export default App;
