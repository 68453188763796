import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";


const AuthContext = createContext()

export default AuthContext;


export const AuthProvider = ({children}) => {

    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
    let [loading, setLoading] = useState(true)

    const [ selectedUser, setSelectedUser ] = useState()

    const navigate = useNavigate()

    const baseURL = process.env.REACT_APP_BASEURL;


    let loginUser = async (e) => {

        e.preventDefault()

        // let response = await fetch("http://170.64.162.112:8000/api/token/", {
        // let response = await fetch("http://localhost:8000/api/token/", {
        let response = await fetch(`${baseURL}/api/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "email": e.target.email.value,
                    "password": e.target.password.value
                }
            )
        })
        let data = await response.json()

        if (response.status === 200) {
            setAuthTokens(data)
            const decoded = jwtDecode(data.access)

            setUser(decoded)

            localStorage.setItem('authTokens', JSON.stringify(data))

            navigate("/")

        } else {
            console.log("Oops!")
        }
    }


    // let registerUser = async (e) => {
    //     e.preventDefault()

    //     if (e.target.password1.value === e.target.password1.value) {

    //         // let response = await fetch("http://170.64.162.112:8000/api/register/", {
    //         let response = await fetch("http://127.0.0.1:8000/api/register/", {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(
    //                 {
    //                     "email": e.target.email.value,
    //                     "password": e.target.password1.value,
    //                     "profile": {
    //                         "firstName": e.target.firstname.value,
    //                         "lastName": e.target.surname.value,
    //                         "telephone": e.target.tel.value,
    //                         "status": "active"
    //                     }
    //                 }
    //             )
                
    //         })
    //         let data = await response.json()
    
    //         console.log(data)
    //         console.log(response)
    
    //         if (response.ok) {
    //             navigate("/login")
    
    //         } else {
    //             alert(data.email[0])
    //         }
    //     }
        
    // }


    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate('/login')
    }


    let updateToken = async ()=> {

        // let response = await fetch('http://170.64.162.112:8000/api/token/refresh/', {
        // let response = await fetch('http://localhost:8000/api/token/refresh/', {
        let response = await fetch(`${baseURL}/api/token/refresh/`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh':authTokens?.refresh})
        })

        let data = await response.json()
        
        if (response.status === 200){
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
        }else{
            logoutUser()
        }

        if(loading){
            setLoading(false)
        }
    }



    let contextData = {
        user: user,
        authTokens: authTokens,

        loginUser: loginUser,
        logoutUser: logoutUser,
        // registerUser: registerUser

        selectedUser: selectedUser,
        setSelectedUser: setSelectedUser,
    }


    useEffect(()=> {

        if(loading){
            updateToken()
        }

        let fourMinutes = 1000 * 60 * 4

        let interval =  setInterval(()=> {
            if(authTokens){
                updateToken()
            }
        }, fourMinutes)
        return ()=> clearInterval(interval)

    }, [authTokens, loading])


    return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
}