import React, { useContext } from 'react'
import AuthContext from "../context/AuthContext"
import { useNavigate } from "react-router-dom"

const LoginPage = () => {

    const navigate = useNavigate()

    let {loginUser} = useContext(AuthContext)

    let handleRegister = () => {
        navigate('/register')
    }


    return (
        <div className="container">
            <h2>User Login</h2>
            <form onSubmit={loginUser}>
        
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input type="password" id="password" name="password" required />
                </div>

                <div className="form-group">
                    <button type="submit">Login</button>
                </div>

                <div className="form-group">
                    <button onClick={handleRegister} style={{backgroundColor: "#228ee8"}}>Register</button>
                </div>
            </form>
        </div>
    )
}

export default LoginPage