import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext"
import { useNavigate } from "react-router-dom"

const HomePage = () => {

    let [users, setUsers] = useState([])
    let {user, authTokens, logoutUser, selectedUser, setSelectedUser} = useContext(AuthContext)

    let [ loggedInUser, setLoggedInUser ] = useState()
    let [ userLoaded, setUserLoaded ] = useState(false)


    const navigate = useNavigate()

    const baseURL = process.env.REACT_APP_BASEURL;


    useEffect(()=> {

        if (!user) {
            return <Navigate to="/login" replace />;
        }

        getUsers()
        getLoggedInUser()
    }, [])


    let getUsers = async() =>{
        // let response = await fetch('http://170.64.162.112:8000/api/list_users', {
        // let response = await fetch('http://localhost:8000/api/list_users', {
        let response = await fetch(`${baseURL}/api/list_users`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json()

        if(response.status === 200){
            setUsers(data)
        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
        
    }


    let getLoggedInUser = async() =>{
        // let response = await fetch('http://170.64.162.112:8000/api/get_logged_in_user', {
        // let response = await fetch('http://localhost:8000/api/get_logged_in_user', {
        let response = await fetch(`${baseURL}/api/get_logged_in_user`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json()

        if(response.status === 200){
            setLoggedInUser(data)
            setUserLoaded(true)
        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
        
    }


    let getSelectedUser = async(user_id) =>{
        // let response = await fetch("http://170.64.162.112:8000/api/get_user_profile/", {
        // let response = await fetch("http://localhost:8000/api/get_user_profile/", {
        let response = await fetch(`${baseURL}/api/get_user_profile/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(
                {
                    user_id: user_id
                }
            )
        })

        let data = await response.json()

        if(response.status === 200){
            setSelectedUser(data)
            navigate("/profile")
        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
        
    }


    let deleteSelectedUser = async(user_id) => {
        console.log(user_id)
        // let response = await fetch("http://170.64.162.112:8000/api/delete_user_profile/", {
        // let response = await fetch("http://localhost:8000/api/delete_user_profile/", {
        let response = await fetch(`${baseURL}/api/delete_user_profile/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(
                {
                    user_id: user_id
                }
            )
        })
        let data = await response.json()

        if(response.status === 200){

            setSelectedUser()
            getUsers()

        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
    }


    return (
        <div>
            <h2>User List</h2>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>Telephone</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Date Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={user.id}>
                                <td>{user.profile.firstName}</td>
                                <td>{user.profile.lastName}</td>
                                <td>{user.profile.telephone}</td>
                                <td>{user.email}</td>
                                <td>{user.profile.status}</td>
                                <td>{user.profile.dateCreated}</td>
                                <td>

                                    <div className="form-group" style={{ display: 'flex', marginBottom: '0px' }}>
                                        <button style={{ marginRight: '10px' }} onClick={() => {getSelectedUser(user.id)}}>Update</button>
                                        <button onClick={() => {deleteSelectedUser(user.id)}}>Delete</button>
                                    </div>

                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>

            <div className="form-group" style={{paddingTop: "10px"}}>
                <button onClick={logoutUser}>Logout</button>
            </div>

            <div>
                <h4>Logged In User:</h4>
                {
                    userLoaded ?
                    <div>
                        <p>UserName: {loggedInUser.email}</p>
                        <p>FirstName: {loggedInUser.profile.firstName}</p>
                        <p>LastName: {loggedInUser.profile.lastName}</p>
                        <p>Status: {loggedInUser.profile.status}</p>
                        <p>Telephone: {loggedInUser.profile.telephone}</p>
                    </div> 
                    :
                    <h1>Still Loading</h1>
                }

            </div>

        </div>
    )
}

export default HomePage