import React, {useContext, useEffect, useState} from 'react'
import AuthContext from '../context/AuthContext'
import { useNavigate } from "react-router-dom"

const RegisterPage = () => {

    const navigate = useNavigate()

    const baseURL = process.env.REACT_APP_BASEURL;

    let {registerUser} = useContext(AuthContext)

    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [telephone, setTelephone] = useState('')
    const [error, setError] = useState(null)


    let handleLogin = () => {
        navigate('/login')
    }




    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password1 !== password2) {
            setError('Passwords do not match')
            return;
        }

        let user = {
            email: email,
            password: password1,
            profile: {
                firstName: firstName,
                lastName: lastName,
                telephone: telephone,
                status: "active"
            }
        }


        try {
            // let response = await fetch("http://170.64.162.112:8000/api/register/", {
            // let response = await fetch("http://localhost:8000/api/register/", {
            let response = await fetch(`${baseURL}/api/register/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })

            let data = await response.json()

            if (response.ok) {
                navigate("/login")

            } else {
                console.log('Error:', response.statusText)
                alert(data.email[0])

            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    };



    return (
        <div className="container">
            <h2>User Registration</h2>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        id="email"
                        name="email"
                    />
                </div>
                <div className="form-group">
                    <label>First Name:</label>
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        id="firstname"
                        name="firstname"
                    />
                </div>
                <div className="form-group">
                    <label>Last Name:</label>
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        id="surname"
                        name="surname"
                    />
                </div>
                <div className="form-group">
                    <label>Telephone:</label>
                    <input
                        type="tel"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        required
                        id="tel"
                        name="tel"
                    />
                </div>
                <div className="form-group">
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value)}
                        required
                        id="password1"
                        name="password1"
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        required
                        id="password2"
                        name="password2"
                    />
                </div>
                
                <div className="form-group">
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>

                <div className="form-group">
                    <button type="submit">Register</button>
                </div>

                <div className="form-group">
                    <button onClick={handleLogin} style={{backgroundColor: "#228ee8"}}>Login</button>
                </div>

            </form>
        </div>
    )
}

export default RegisterPage